<template>
  <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
            icon="AlertCircleIcon"
            size="18"
            class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('General') }}</span>
      </template>

      <BatchCreateGeneral
          v-if="batchData"
          :general-data="batchData"
      />
    </b-tab>
    <!--/ general tab -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import BatchCreateGeneral from './BatchCreateGeneral.vue'
import useJwt from '@/auth/jwt/useJwt'


export default {
  components: {
    BTabs,
    BTab,
    BatchCreateGeneral
  },
  data() {
    return {
      batchData: {}
    }
  },
  setup(){
    const { t } = useI18nUtils()
    return {
      t
    }
  },
  async beforeCreate() {
    await useJwt.getData("tracking/batch/create/?status=creating").then(response => {
      this.batchData = response.data
    })
  },
}
</script>
