import { render, staticRenderFns } from "./BatchCreateGeneral.vue?vue&type=template&id=68de5aaa&"
import script from "./BatchCreateGeneral.vue?vue&type=script&lang=js&"
export * from "./BatchCreateGeneral.vue?vue&type=script&lang=js&"
import style0 from "./BatchCreateGeneral.vue?vue&type=style&index=0&id=68de5aaa&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports